import React from 'react';

export function SvgMenu(props) {
	const { type, fill } = props;

	switch (type) {
		case 'wallet':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M21.6 16.56v1a1 1 0 001-1h-1zm0-5.28h1a1 1 0 00-1-1v1zM7.14 6.119A1 1 0 108.22 7.8L7.14 6.12zm7.26-3.48l.79-.613a1 1 0 00-1.33-.227l.54.84zm2.57 4.935a1 1 0 001.58-1.228l-1.58 1.228zm4.63 7.986h-3.12v2h3.12v-2zm-3.12-3.28h3.12v-2h-3.12v2zm2.12-1v5.28h2v-5.28h-2zm-3.76 2.64c0-.906.734-1.64 1.64-1.64v-2a3.64 3.64 0 00-3.64 3.64h2zm1.64 1.64a1.64 1.64 0 01-1.64-1.64h-2a3.64 3.64 0 003.64 3.64v-2zM8.22 7.8l6.72-4.32L13.86 1.8l-6.72 4.32L8.22 7.8zm5.39-4.547l3.36 4.32 1.58-1.228-3.36-4.32-1.58 1.228zM3.36 7.96h16.32v-2H3.36v2zm16.32 12.4H3.36v2h16.32v-2zM3.4 20.4V7.92h-2V20.4h2zm-.04-.04a.04.04 0 01.04.04h-2a1.96 1.96 0 001.96 1.96v-2zm16.28.04a.04.04 0 01.04-.04v2a1.96 1.96 0 001.96-1.96h-2zm.04-12.44a.04.04 0 01-.04-.04h2a1.96 1.96 0 00-1.96-1.96v2zm-16.32-2A1.96 1.96 0 001.4 7.92h2a.04.04 0 01-.04.04v-2zm16.28 11.32v3.12h2v-3.12h-2zm0-9.36v2.52h2V7.92h-2z'
						fill='#fff'
					/>
				</svg>
			);
		case 'import':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M12.001 14.943V3m0 0L7.068 7.563M12.001 3l4.933 4.563M4 18.966v1.417c.405.395.954.617 1.526.617h12.948c.572 0 1.121-.222 1.526-.617v-1.417'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'backup':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M4.871 14.5C5.89 17.694 8.805 20 12.242 20 16.527 20 20 16.418 20 12s-3.473-8-7.758-8c-2.871 0-5.378 1.609-6.72 4m-.65-.782L5.94 8.25M7.879 9H4V5l3.879 4z'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'security':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M6.6 8.8v-.914C6.6 4.846 9.01 2.4 12 2.4c2.992 0 5.4 2.447 5.4 5.486V8.8m-10.8 0c-.99 0-1.8.823-1.8 1.829v9.142c0 1.006.81 1.829 1.8 1.829h10.8c.99 0 1.8-.823 1.8-1.829v-9.143c0-1.005-.81-1.828-1.8-1.828m-10.8 0h10.8M12 16.2v-2.4'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
					/>
				</svg>
			);
		case 'bell':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M4.601 16.476l-.82-.571.82.57zm1.09-3.373l-1-.01v.01h1zm.023-2.039l1 .011v-.01h-1zm13.693 5.428l.831-.555-.831.555zm-1.05-3.389l-1-.011v.011h1zm.023-1.938l1 .012v-.012h-1zm-8.256 8.204a1 1 0 10-1.245 1.565l1.245-1.565zm4.999 1.565a1 1 0 10-1.245-1.565l1.245 1.565zM3.065 6.024a1 1 0 101.87.71l-1.87-.71zM7.31 3.869a1 1 0 00-.988-1.738l.988 1.738zm10.357-1.724a1 1 0 00-1.007 1.728l1.007-1.728zm1.395 4.57a1 1 0 001.876-.69l-1.876.69zM5.422 17.048c.638-.916 1.27-2.28 1.27-3.944h-2c0 1.151-.44 2.124-.911 2.802l1.641 1.142zm1.27-3.932l.022-2.04-2-.022-.022 2.04 2 .022zm13.546 2.822c-.452-.677-.88-1.662-.88-2.834h-2c0 1.662.605 3.028 1.217 3.945l1.663-1.11zm-.88-2.822l.022-1.938-2-.024-.022 1.939 2 .023zm.022-1.95c0-4.312-3.223-7.96-7.379-7.96v2c2.89 0 5.38 2.584 5.38 5.96h2zm-.355 7.272c.768 0 1.199-.579 1.355-.98.162-.414.199-1.01-.142-1.52l-1.663 1.11a.423.423 0 01-.075-.21.248.248 0 01.017-.107.39.39 0 01.114-.151.61.61 0 01.394-.142v2zm-12.31-7.373c0-3.32 2.447-5.86 5.286-5.86v-2c-4.105 0-7.287 3.603-7.287 7.86h2zm-1.737 5.373a.61.61 0 01.399.145c.073.064.1.123.11.15.011.028.018.06.015.102a.426.426 0 01-.08.213l-1.641-1.142c-.357.512-.322 1.117-.164 1.534.152.402.58.998 1.361.998v-2zm14.047 0H4.978v2h14.047v-2zM12 20a3.012 3.012 0 01-1.877-.63l-1.245 1.564A5.011 5.011 0 0012.001 22v-2zm1.877-.63c-.483.384-1.14.63-1.877.63v2a5.01 5.01 0 003.122-1.066l-1.245-1.565zM4.935 6.734c.473-1.247 1.311-2.261 2.375-2.866l-.988-1.738c-1.509.857-2.635 2.258-3.257 3.893l1.87.71zM16.66 3.873a5.497 5.497 0 012.4 2.843l1.877-.692a7.498 7.498 0 00-3.27-3.879L16.66 3.873z'
						fill='#fff'
					/>
				</svg>
			);
		case 'rpc':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M7.2 16.8L2.4 12l4.8-4.8m9.6 0l4.8 4.8-4.8 4.8'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'connect':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M4 15V9m8 11V4M8 17V7m12 2v6m-4-8v10'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'about':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M12 12v4.5m0-7.835v-.04M3 12a9 9 0 1118 0 9 9 0 01-18 0z'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'success':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M22 11.5a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0z'
						fill='#5ABE51'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M16.757 8.24c.316.328.325.87.02 1.21l-4.651 5.2a1.008 1.008 0 01-1.55-.03L8.203 11.77a.904.904 0 01.06-1.21.755.755 0 011.122.067l1.993 2.395 4.256-4.76a.754.754 0 011.123-.021z'
						fill='#fff'
					/>
				</svg>
			);
		case 'error':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M12 12.9V8.414m0 7.81v.04M17.67 20H6.33a3.398 3.398 0 01-3.267-2.424c-.176-.598.04-1.22.37-1.751L9.103 5.6c1.328-2.135 4.466-2.135 5.794 0l5.67 10.224c.33.53.546 1.153.37 1.75A3.398 3.398 0 0117.67 20z'
						stroke='#C05567'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'send':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M15.53 13.059l1.43-4.292c1.25-3.748 1.874-5.623.885-6.612-.99-.99-2.864-.365-6.612.885L6.942 4.47C3.916 5.48 2.403 5.983 1.973 6.722a2.264 2.264 0 000 2.276c.43.74 1.943 1.244 4.969 2.252a1.06 1.06 0 001.077-.242l4.588-4.546a.658.658 0 11.926.935L9.02 11.87c-.31.307-.407.776-.27 1.19 1.01 3.025 1.513 4.538 2.253 4.968a2.264 2.264 0 002.275 0c.74-.43 1.244-1.943 2.253-4.969z'
						fill='#fff'
					/>
				</svg>
			);
		case 'receive':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M2.887 2.887c-1.22 1.22-1.22 3.185-1.22 7.113 0 3.928 0 5.893 1.22 7.113 1.22 1.22 3.185 1.22 7.113 1.22 3.928 0 5.893 0 7.113-1.22 1.22-1.22 1.22-3.185 1.22-7.113 0-3.928 0-5.893-1.22-7.113-1.22-1.22-3.185-1.22-7.113-1.22-3.928 0-5.893 0-7.113 1.22zM10 6.042c.345 0 .625.28.625.625v5.157l1.433-1.433a.625.625 0 11.884.884l-2.5 2.5a.625.625 0 01-.884 0l-2.5-2.5a.625.625 0 01.884-.884l1.433 1.433V6.667c0-.346.28-.625.625-.625z'
						fill='#fff'
					/>
				</svg>
			);
		case 'buy':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.645 3.91c-.694.576-.877 1.551-1.243 3.502l-.625 3.333c-.514 2.743-.772 4.115-.022 5.018.75.904 2.145.904 4.936.904h4.618c2.79 0 4.186 0 4.936-.904.75-.903.492-2.275-.022-5.018l-.625-3.333c-.366-1.95-.549-2.926-1.243-3.502-.694-.577-1.687-.577-3.671-.577H8.316c-1.984 0-2.977 0-3.671.577zm3.587 2.548a1.876 1.876 0 003.537 0 .625.625 0 111.178.417 3.126 3.126 0 01-5.894 0 .625.625 0 011.179-.417z'
						fill='#fff'
					/>
				</svg>
			);
		case 'home':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M7 19v-6.847c0-.63.537-1.141 1.2-1.141h3.6c.663 0 1.2.51 1.2 1.14V19M9.305 1.211l-7.8 5.275c-.317.214-.505.56-.505.93v9.872C1 18.234 1.806 19 2.8 19h14.4c.994 0 1.8-.766 1.8-1.712V7.416c0-.37-.188-.716-.505-.93l-7.8-5.275a1.25 1.25 0 00-1.39 0z'
						stroke={fill ? fill : 'var(--light)'}
						strokeWidth={2}
						strokeLinecap='round'
					/>
				</svg>
			);
		case 'buy-crypto':
			return (
				<svg
					width={22}
					height={22}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M20.6 15.56v1a1 1 0 001-1h-1zm0-5.28h1a1 1 0 00-1-1v1zM6.14 5.119A1 1 0 107.22 6.8L6.14 5.12zM13.4 1.64l.79-.614A1 1 0 0012.86.8l.54.841zm2.57 4.934a1 1 0 001.58-1.228l-1.58 1.228zm4.63 7.986h-3.12v2h3.12v-2zm-3.12-3.28h3.12v-2h-3.12v2zm2.12-1v5.28h2v-5.28h-2zm-3.76 2.64c0-.906.734-1.64 1.64-1.64v-2a3.64 3.64 0 00-3.64 3.64h2zm1.64 1.64a1.64 1.64 0 01-1.64-1.64h-2a3.64 3.64 0 003.64 3.64v-2zM7.22 6.801l6.72-4.32L12.86.8l-6.72 4.32L7.22 6.8zm5.39-4.547l3.36 4.32 1.58-1.228-3.36-4.32-1.58 1.228zM2.36 6.96h16.32v-2H2.36v2zm16.32 12.4H2.36v2h16.32v-2zM2.4 19.4V6.92h-2V19.4h2zm-.04-.04a.04.04 0 01.04.04h-2a1.96 1.96 0 001.96 1.96v-2zm16.28.04a.04.04 0 01.04-.04v2a1.96 1.96 0 001.96-1.96h-2zm.04-12.44a.04.04 0 01-.04-.04h2a1.96 1.96 0 00-1.96-1.96v2zm-16.32-2A1.96 1.96 0 00.4 6.92h2a.04.04 0 01-.04.04v-2zm16.28 11.32v3.12h2v-3.12h-2zm0-9.36v2.52h2V6.92h-2z'
						fill={fill ? fill : 'var(--light)'}
					/>
				</svg>
			);
		case 'swap':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10.2 7.8a5.4 5.4 0 115.916 6.561M6.6 6L9 3.6 6.6 1.2M1.8 8.4V6.8A3.2 3.2 0 015 3.6h2.4m10 14.4L15 20.4l2.4 2.4m4.8-7.2v1.6a3.2 3.2 0 01-3.2 3.2h-2.4m-4-4.2a5.4 5.4 0 11-10.8 0 5.4 5.4 0 0110.8 0z'
						stroke={fill ? fill : 'var(--light)'}
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'settings':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						clipRule='evenodd'
						d='M11.676 2.316c-.427-1.755-2.925-1.755-3.352 0a1.724 1.724 0 01-2.572 1.067c-1.544-.94-3.31.826-2.37 2.37a1.725 1.725 0 01-1.065 2.572c-1.756.426-1.756 2.925 0 3.35a1.724 1.724 0 011.066 2.573c-.941 1.544.825 3.31 2.369 2.37a1.723 1.723 0 012.573 1.065c.426 1.756 2.925 1.756 3.35 0a1.723 1.723 0 012.573-1.066c1.544.941 3.31-.825 2.37-2.369a1.723 1.723 0 011.065-2.573c1.756-.426 1.756-2.925 0-3.35a1.724 1.724 0 01-1.066-2.573c.941-1.543-.825-3.31-2.369-2.37a1.724 1.724 0 01-2.573-1.065h.001z'
						stroke={fill ? fill : 'var(--light)'}
						strokeWidth={2}
					/>
					<path
						d='M12 10a2 2 0 11-4 0 2 2 0 014 0z'
						stroke={fill ? fill : 'var(--light)'}
						strokeWidth={2}
					/>
				</svg>
			);
		case 'phrase':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M15 2.4V6a1.2 1.2 0 001.2 1.2h3.6m-11.4 0h2.4m-2.4 3.6h7.2m-7.2 3.6h7.2M18 4.2a26.487 26.487 0 01-1.438-1.413 1.234 1.234 0 00-.892-.387H6.6a2.4 2.4 0 00-2.4 2.4v14.4a2.4 2.4 0 002.4 2.4h10.8a2.4 2.4 0 002.4-2.4V6.478c0-.307-.117-.602-.33-.823-.394-.408-1.051-1.08-1.47-1.455z'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'key':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M7.679 6.6H7.8m6.818 2.782l6.69 6.689a1 1 0 01.292.707V20.6a1 1 0 01-1 1h-2.49v-2.618h-2.62v-2.619l-3.224-3.036a6.11 6.11 0 112.352-4.818v.873z'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		case 'basket':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M4 6.176h16M9 3h6m-5 13.765v-6.353m4 6.353v-6.353M15.5 21h-7c-1.105 0-2-.948-2-2.118L6.043 7.28c-.023-.601.43-1.103 1-1.103h9.915c.568 0 1.022.502.999 1.103L17.5 18.882c0 1.17-.895 2.118-2 2.118z'
						stroke='#fff'
						strokeWidth={2}
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			);
		default:
			break;
	}
}
